import React from "react"
import Layout from "../../../../components/layout"
import Formrelationretro from "../../../../components/formrelationretro"
import SEO from "../../../../components/seo"
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import "../../../../assets/css/closedform.css";
import $ from 'jquery';

let title = 'Retrofitted - LS thresholds: Closed-form relationships';
let metatitle = 'Retrofitted - LS thresholds: Closed-form relationships';
let description = '';
let metadescription = description;





class ClosedFormr extends React.Component{
  //javascript
  state = {
    
  }

  
  render(){
  //   if (typeof window !== 'undefined') {

  //   $(document).ready(function () {
  //     $('.anchornav button').click(function(){
  //       var target = $(this).attr('data-target');
  //       var off = 20;
  //       var offset = $('#'+target).offset().top - off;
  //       $('html,body').animate({scrollTop: offset},'slow');
  //     });
  //   });
  // }
    return(
      
        <Layout bodyclass="retrofitted" title={title}>
          <SEO
            title={metatitle}
            description={metadescription}
          />
          <nav className="anchornav">
            <ul>
          {this.props.closedforms.edges.map(edge=>{
            let entity = edge.node;
            return(
              <li key={entity.id}>
                <Link className="more" to={`/bridge-specific/capacity/retrofitted/ls-closed-form/${entity.slug}`}>{entity.Name}</Link>
              </li>
            )
            })}
          
          </ul>
          </nav>
        </Layout>
      )
    }
}


export default () => (
  <StaticQuery
  query={graphql`
  query MyQueryRetro {
    allStrapiCapacityRetrofittedClosedForms{
    edges {
      node {
        id
        Name
        slug
      }
    }
  }
}
    `
  }
    render={(data) => (
      <ClosedFormr closedforms={data.allStrapiCapacityRetrofittedClosedForms} />
    )}
  />
)
