/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import $ from 'jquery';


import "./formrelation.css";

class Formrelationretro extends React.Component{
    //javascript
    state = {
      Dj:"",
      Dc:"",
      plj:"",
      plc:"",
      pwj:"",
      pwc:"",
      Fcj:"",
      Fcc:"",
      Fyj:"",
      Fyc:"",
      Ej:"",
      Ec:"",
      Fj:"",
      Fc:"",
      pj:"",
      pw:"",
      result: "",
    }
    handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      const value2 = value.replace(",",".");
      
      this.setState({
        [name]: value2,
      })
    }
  
    handleSubmit = event => {
      
      event.preventDefault();
      
      if(this.props.length > 0){
        let b0 = this.props.b0;
      }
      if(this.props.length > 1){
        let b1 = this.props.b1;
      }
      if(this.props.length > 2){
        let b2 = this.props.b2;
      }
      if(this.props.length > 3){
        let b3 = this.props.b3;
      }
      if(this.props.length > 4){
        let b4 = this.props.b4;
      }
      if(this.props.length > 5){
        let b5 = this.props.b5;
      }
      if(this.props.length > 6){
        let b6 = this.props.b6;
      }
     
      let answerpre = 'let Dj, Dc';
      
      if(this.props.id == 'Capacity-retrofitted-closed-forms_2' || this.props.id == 'Capacity-retrofitted-closed-forms_4'){
        
            answerpre += ', Ej, Ec, Fj, Fc, pj, pw;';
            answerpre += 'Dj = ' + this.state.Dj + '; Dc = ' + this.state.Dc + '; Ej = ' + this.state.Ej + '; Ec = ' + this.state.Ec + '; Fj = ' + this.state.Fj + '; Fc = ' + this.state.Fc + '; pj = ' + this.state.pj + '; pw = ' + this.state.pw + '; ';
        
      }else if(this.props.id == 'Capacity-retrofitted-closed-forms_1' || this.props.id == 'Capacity-retrofitted-closed-forms_3'){
        
            answerpre += ', plj, plc, pwj, pwc, Fcj, Fcc, Fyj, Fyc;';
            answerpre += 'Dj = ' + this.state.Dj + '; Dc = ' + this.state.Dc + '; plj = ' + this.state.plj + '; plc = ' + this.state.plc + '; pwj = ' + this.state.pwj + '; pwc = ' + this.state.pwc + '; Fcj = ' + this.state.Fcj + '; Fcc = ' + this.state.Fcc + '; Fyj = ' + this.state.Fyj + '; Fyc = ' + this.state.Fyc + '; ';
      }
      
      let equation = this.props.relationship;
      console.log(equation);
      let answerstring = answerpre + equation;
      let answer = eval(answerstring);
      answer = (Math.round(answer * 1000000) / 1000000).toFixed(6);
      this.setState({
        result: answer,
      })
  
    }
    
  
    
    render(){
        let Ej, Ec, Fj, Fc, pj, pw, plj, plc, pwj, pwc, Fcj, Fcc, Fyj, Fyc;
        

        if(this.props.id == 'Capacity-retrofitted-closed-forms_2' || this.props.id == 'Capacity-retrofitted-closed-forms_4'){
            Ej = <label>
            E<sub>j</sub> (MPa):
            <input
                type="text"
                name="Ej"
                value={this.state.Ej}
                onChange={this.handleInputChange}
            />
            </label>;
            Ec = <label>
            E<sub>c</sub> (MPa):
            <input
                type="text"
                name="Ec"
                value={this.state.Ec}
                onChange={this.handleInputChange}
            />
            </label>;
            Fj = <label>
            F<sub>j</sub> (MPa):
            <input
                type="text"
                name="Fj"
                value={this.state.Fj}
                onChange={this.handleInputChange}
            />
            </label>;
            Fc = <label>
            F<sub>c</sub> (MPa):
            <input
                type="text"
                name="Fc"
                value={this.state.Fc}
                onChange={this.handleInputChange}
            />
            </label>;
            pj = <label>
            ρ<sub>j</sub>:
            <input
                type="text"
                name="pj"
                value={this.state.pj}
                onChange={this.handleInputChange}
            />
            </label>;
            pw = <label>
            ρ<sub>w</sub>:
            <input
                type="text"
                name="pw"
                value={this.state.pw}
                onChange={this.handleInputChange}
            />
            </label>;
                
            
          }else if(this.props.id == 'Capacity-retrofitted-closed-forms_1' || this.props.id == 'Capacity-retrofitted-closed-forms_3'){
            plj = <label>
            ρ<sub>lj</sub>:
            <input
                type="text"
                name="plj"
                value={this.state.plj}
                onChange={this.handleInputChange}
            />
            </label>;
            plc = <label>
            ρ<sub>lc</sub>:
            <input
                type="text"
                name="plc"
                value={this.state.plc}
                onChange={this.handleInputChange}
            />
            </label>;
            pwj = <label>
            ρ<sub>wj</sub>:
            <input
                type="text"
                name="pwj"
                value={this.state.pwj}
                onChange={this.handleInputChange}
            />
            </label>;
            pwc = <label>
            ρ<sub>wc</sub>:
            <input
                type="text"
                name="pwc"
                value={this.state.pwc}
                onChange={this.handleInputChange}
            />
            </label>;
            Fcj = <label>
            f<sub>cj</sub> (MPa):
            <input
                type="text"
                name="Fcj"
                value={this.state.Fcj}
                onChange={this.handleInputChange}
            />
            </label>;
            Fcc = <label>
            f<sub>cc</sub> (MPa):
            <input
                type="text"
                name="Fcc"
                value={this.state.Fcc}
                onChange={this.handleInputChange}
            />
            </label>;
            Fyj = <label>
            f<sub>yj</sub> (MPa):
            <input
                type="text"
                name="Fyj"
                value={this.state.Fyj}
                onChange={this.handleInputChange}
            />
            </label>;
            Fyc = <label>
            f<sub>yc</sub> (MPa):
            <input
                type="text"
                name="Fyc"
                value={this.state.Fyc}
                onChange={this.handleInputChange}
            />
            </label>;
            
          }
        
      return(
          <>
        <form onSubmit={this.handleSubmit}>
        <label>
        D<sub>j</sub> (m):
        <input
            type="text"
            name="Dj"
            value={this.state.Dj}
            onChange={this.handleInputChange}
        />
        </label>
        <label>
        D<sub>c</sub> (m):
        <input
            type="text"
            name="Dc"
            value={this.state.Dc}
            onChange={this.handleInputChange}
        />
        </label>
        {pj}
        {pw}
        {plc}
        {pwc}
        {plj}
        {pwj}
        {Ej}
        {Ec}
        {Fc}
        {Fj}
        {Fcc}
        {Fcj}
        {Fyc}
        {Fyj}
        
    
        <button type="submit">Calculate</button>
    </form>
    <div className="result"><span dangerouslySetInnerHTML={{__html: this.props.param}} /> : {this.state.result}</div>
    </>
      )
    }
}
export default Formrelationretro
